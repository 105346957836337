// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout pageTitle="Picture Library">
      <StaticImage 
      alt='snap is a 6 week old black kitten having his first cuddle'
      src='../images/kitten.jpg'
      />
      <p>Picture taken in July 2022 courtesy of </p>
      <a href="http://www.coolforcatscafe.co.uk/">Cool For Cats Cafe, Devon</a>
      <iframe src="https://app.narbl.uk/secure399/trackdate" height={30} width={360}></iframe><p/>
    </Layout>
  )
}

// Step 3: Export your component
export default IndexPage